.sound {
  flex: 0 0 calc(100% / 3);
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .sound {
    flex: 0 0 calc(100% / 2);
  }
}

@media screen and (max-width: 600px) {
  .sound {
    flex: 0 0 100%;
  }
}
