html {
  scroll-behavior: smooth;
  background-size: cover;
  background-color: #40bff0;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
  body {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  body {
    font-size: 11px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "riftonnormal";
  src: url("fonts/rifton-normal-webfont.woff2") format("woff2"),
    url("fonts/rifton-normal-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
